body {
    background-color: white;
}
#auth {
    height: 100vh;
    overflow-x: hidden;

    #auth-right {
        height: 100%;
        background-image: url("/assets/img/auth/capa-auth.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        // background-color: #05586d75;
        // background-blend-mode: multiply;
    }
    #auth-left {
        padding: 5rem 8rem;

        .auth-title {
            font-size: 4rem;
            margin-bottom: 1rem;
        }
        .auth-subtitle {
            font-size: 1.7rem;
            line-height: 2.5rem;
            color: #a8aebb;
        }
        .auth-logo {
            margin-bottom: 4rem;
            text-align: center;
            img {
                height: 4rem;
            }
        }
        @media screen and (max-width: 767px) {
            padding: 5rem;
        }
    }
}
